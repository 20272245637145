import { useState, useEffect } from 'react'

const useArrival = (raw, when=0) => {
  const [ arrived, setArrived ] = useState(false);
  
  useEffect(() => {
    return raw.onChange(v => {
      setArrived(v>=when)
    })
  }, [ raw, setArrived, when ])
  
  return arrived
}

export default useArrival