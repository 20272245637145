import { useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom';

const listeners = [];

let size = [0,0];

const updateSize = () => {
  size = [window.innerWidth,window.innerHeight]
  
  unstable_batchedUpdates(() => {
    listeners.forEach(l => l(size))
  });
}

const useScreenSize = () => {
  const [ lsize, setSize ] = useState(size)
  useEffect(() => {
    if (listeners.length === 0) {
      window.addEventListener('resize', updateSize)
      if (size[0] === 0) updateSize();
    }
    
    listeners.push(setSize);
    setSize(size);
    
    
    return () => {
      const index = listeners.indexOf(setSize);
      if (index > -1) listeners.splice(index, 1);
      if (listeners.length === 0) window.removeEventListener('resize', updateSize)
    }
  }, [ setSize ])
  
  return lsize;
}

export default useScreenSize;