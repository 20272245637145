import React, { useState, useEffect } from 'react'

import { useArrival } from 'src/hooks'

const DelayedSvg = ({ raw, children, ...p }) => {
  const arrived = useArrival(raw, -0.999);
  const [ loaded, setLoaded ] = useState(false)
  
  useEffect(() => {
    setLoaded(l => l || arrived)
  }, [ arrived, setLoaded ])
  
  return <svg {...p}>
    { loaded && children() }
  </svg>
}

export default DelayedSvg