import React from 'react';
import { motion, useTransform } from 'framer-motion'

import { useArrival } from 'src/hooks'

import DelayedSvg from '../delayed-svg'
import layers from './layers'

const Connected = ({ progress, raw }) => {
  const scale = useTransform(progress, [-1,0], [0,1])
  const scaleLogo = useTransform(progress, [-0.5,-0.1], [0,1])
  
  return <div>
    <DelayedSvg raw={raw} width="100%" height="25em" viewBox={`0 0 640 350`} preserveAspectRatio="xMidYMid meet">
      {() => (
        <motion.g
          style={{
            translateX: 320,
            translateY: 147,
          }}
        >
          <Components raw={raw}/>
          <motion.g 
            style={{ translateX: -56, translateY:-143 }}
          >
            <g dangerouslySetInnerHTML={{ __html: layers.center }} />
            <motion.g
              style={{ scale: scaleLogo }}
              dangerouslySetInnerHTML={{ __html: layers.logo }}
            />
            <motion.g
              style={{
                scale,
                originY: '264px',
              }}
              dangerouslySetInnerHTML={{ __html: layers.light }}
            />
          </motion.g>
        </motion.g>
      )}
    </DelayedSvg>
  </div>
}

export default Connected

const parentVariants = {
  hidden: {
    transition: { staggerChildren: 0.05 }
  },
  visible: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
}
const Components = ({ raw }) => {
  const arrived = useArrival(raw, -0.1)
  
  return <motion.g
    initial={false}
    variants={parentVariants}
    animate={arrived ? 'visible' : 'hidden'}
  >
    <Component pos={[-103,-75]} scale={0.9} />
    <Component pos={[151,111]} scale={1} />
    <Component pos={[0,-93]} scale={0.75} />
    <Component pos={[151,-111]} scale={0.65} />
    <Component pos={[97,0]} scale={0.8} />
    <Component pos={[-96,0]} scale={0.45} />
    <Component pos={[-151,111]} scale={0.7} />
  </motion.g>
}

const childrenVariants = {
  hidden: () => ({
    opacity: 0,
    translateX: 0,
    translateY: 0,
    scale: 0,
  }),
  visible: (custom) => ({
    opacity: 1,
    scale: 1,
    ...custom,
  })
}

const lineVariants = {
  hidden: { pathLength: 0 },
  visible: { pathLength: 1 },
}



const Component = ({ pos, scale }) => {
  return <motion.g>
    <motion.path
      variants={lineVariants}
      d={`M0 0 L${pos[0]} ${pos[1]}`}
      stroke="#E1E0E0"
      stroke-width="2"
    />
    <motion.g
      variants={childrenVariants}
      custom={{
        translateX:pos[0],
        translateY:pos[1],
      }}
    >
      <motion.g
        style={{
          translateX: -40,
          translateY: -40,
          scale,
        }}
        dangerouslySetInnerHTML={{ __html: layers.component }}
      />
    </motion.g>
  </motion.g>
}

// <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M39.5 77.2001C60.3764 77.2001 77.3 60.2765 77.3 39.4001C77.3 18.5237 60.3764 1.6001 39.5 1.6001C18.6236 1.6001 1.70001 18.5237 1.70001 39.4001C1.70001 60.2765 18.6236 77.2001 39.5 77.2001Z" stroke="#FFBC00" stroke-width="2" stroke-miterlimit="10"/>
// <path d="M39.5 39.8999L24.5 31.2V48.3999L39.5 57.1V39.8999Z" stroke="#00E0C7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M39.5 39.8999L54.5 31.2V48.3999L39.5 57.1V39.8999Z" stroke="#00E0C7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M24.5 31.2L39.5 21.7L54.5 31.2L39.5 39.8999L24.5 31.2Z" stroke="#00E0C7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
// </svg>

      // MANUAL Component circle + path
      // <motion.circle
      //   cx={0}
      //   cy={0}
      //   r={(scale*37).toFixed(2)}
      //   fill="white"
      //   stroke="#FFBC00"
      //   strokeWidth="2"
      // />
      // <path d="M3.8 6.4999L-8.5 -0.6L-8.6 13.4999L3.8 20.5999V6.4999Z" stroke="#00E0C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      // <path d="M3.8 6.4999L16.1 -0.6V13.4999L3.8 20.5999V6.4999Z" stroke="#00E0C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      // <path d="M-8.5 -0.6L3.8 -8.5L16.1 -0.69L3.8 6.5L-8.5 -0.69Z" stroke="#00E0C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>