import React from 'react'

import { Modal, Button } from 'src/styled-components'

import s from './wheel-warning.module.css'

const WheelWarning = ({ resolve }) => {
  return <Modal title="Zoom sur la roulette" style={{width: '35vw'}}>
    <div>
      <p>La roulette sert au zoom sur la carte.</p>
      <p>
        Cliquez sur <Button className={s['btn']}><span className={s['arrow']} /></Button> pour quitter le cockpit
      </p>
    </div>
    <div className={s['actions']}>
      <Button onClick={resolve}>J'ai compris</Button>
    </div>
  </Modal>
}

export default WheelWarning