import React from 'react';

const Logo = () => {
  return <div>
    <svg width="100%" height="10em" viewBox={`-20 -20 90 90`} preserveAspectRatio="xMidYMid meet">
      <path fill="#E5E5E5" d="M24.98,0C11.2,0,0,11.2,0,24.98c0,13.77,11.2,24.98,24.98,24.98c13.77,0,24.98-11.2,24.98-24.98
	C49.95,11.2,38.75,0,24.98,0 M26.93,47.13c-3.25,0.28-6.36-0.15-9.22-1.13c-0.75-0.26-1.47-0.59-2.11-1.05
	c-5.57-4.02,0.03-11.23,4.66-15.8c2.32-2.29,2.32-6.05,0-8.34c-4.62-4.57-10.23-11.78-4.66-15.8c0.64-0.46,1.36-0.8,2.11-1.05
	c2.28-0.79,4.72-1.22,7.26-1.22c12.81,0,23.09,10.83,22.19,23.84C46.41,37.4,37.75,46.2,26.93,47.13"/>
    </svg>
    <div style={{
      textAlign: 'center',
      marginTop:'-6em',
      lineHeight: '2em',
      color: '#aaa',
      textTransform: 'uppercase',
    }}>
      Aucune image
    </div>
  </div>
}

export default Logo