import React from 'react'
import { motion, useTransform } from 'framer-motion'

import { useArrival } from 'src/hooks'

import DelayedSvg from '../delayed-svg'
import layers from './layers'

const Cockpit = ({ progress, raw }) => {
  const arrived = useArrival(raw)
  
  return <div>
    <DelayedSvg raw={raw} width="100%" height="25em" viewBox={`0 0 640 350`} preserveAspectRatio="xMidYMid meet">
      {() => (
        <motion.g style={{
          translateX: 35,
          translateY: 20,
        }}>
          <RightPopup arrived={arrived} />
          <BackScreens progress={progress} />
          <g dangerouslySetInnerHTML={{ __html: layers.screens }} />
          <Man progress={progress} />
          <LeftPopup arrived={arrived} />
        </motion.g>
      )}
    </DelayedSvg>
  </div>
}

export default Cockpit



const Man = ({ progress }) => {
  const translateY = useTransform(progress, [-1,0,1], [100,0,-50])
  
  return <motion.g style={{ translateX: 192, translateY: 61 }}>
    <motion.g style={{ translateY }} dangerouslySetInnerHTML={{ __html: layers.man }} />
  </motion.g>
}

const BackScreens = ({ progress }) => {
  const translateY = useTransform(progress, [-1,0,1], [-50,0,10])
  
  return <motion.g style={{ translateY }} dangerouslySetInnerHTML={{ __html: layers.backScreens }} />
}

const RightPopup = ({ arrived }) => {
  return <motion.g
    initial={false}
    animate={{ scaleY: arrived ? 1 : 0 }}
    dangerouslySetInnerHTML={{ __html: layers.rightPopup }}
  />
}
const LeftPopup = ({ arrived }) => {
  return <motion.g
    initial={false}
    animate={{
      scale: arrived ? 1 : 0,
      opacity: arrived ? 1 : 0,
      originX: '60px',
      originY: '75px',
    }}
    transition={{
      delay: .5
    }}
    dangerouslySetInnerHTML={{ __html: layers.leftPopup }}
  />
}