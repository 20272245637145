export default {
  all: `<path d="M640 0H0V501H640V0Z" fill="white"/>
<path d="M227.8 330.8C249.4 384.9 223.1 446.3 169.1 467.9C135.8 481.2 99.7 476.4 71.8 458C54.4 446.6 40.3 429.9 32 409.2C10.4 355.1 36.7 293.7 90.7 272.1C111.5 263.8 133.4 262.5 153.6 267.2C186.1 274.8 214.4 297.5 227.8 330.8Z" fill="#00E0C7"/>
<path d="M117.2 280C112.4 280.1 106.2 281.8 110.7 284.5C115.2 287.2 118 287.5 118.1 291.1C118.1 294.7 115.8 298.4 117 300.9C118.1 303.4 120 306.4 121.9 305.5C123.7 304.6 128.8 299 131.6 299.3C134.5 299.6 144.2 300 145.6 296.8C147 293.6 148.5 293.8 150.8 290.3C153.1 286.8 153 282 150.2 282C147.4 282 147 283.2 142.2 282.8C137.4 282.5 135.2 279.3 130.6 280.1C125.8 281 120.8 279.9 117.2 280Z" fill="#FF8400"/>
<path d="M99.3 281.3C93.8 284.2 82.3 288.6 77 287.2C74.4 286.4 71.2 285.4 67.8 284.8C47.5 299.6 32.8 321.4 27 346.4C28.3 349.1 30 351.7 31.8 353.8C36 358.7 40.3 359.3 45.6 360.3C50.9 361.3 49.2 364.3 51.8 367.4C54.4 370.5 55.5 372.3 58.5 374C61.5 375.7 57.4 378.8 56.3 382.2C55.1 385.6 54.8 387.5 57.2 394.5C59.6 401.4 66.7 407.1 68.8 409.8C70.9 412.5 67.4 415.6 68.3 421.2C69.2 426.8 68.9 441.8 74.3 450.1C79.7 458.5 82.9 457.5 82.5 451.9C82.1 446.2 80.2 440.9 83.5 436.6C86.8 432.3 93.4 433.5 95.7 425.7C98 417.9 99.4 418.3 103 415.7C106.6 413 105 410.8 108.3 404.8C111.6 398.8 112.6 393.1 107.7 390.7C102.8 388.2 99.5 388.5 95.2 382.6C91 376.8 84.4 371.1 78.2 367.8C72.1 364.5 62.3 368.5 58.4 367.4C54.5 366.3 52.1 358.9 52.3 356C52.6 353.1 49.7 352.4 46.9 353.1C44.1 353.8 42.5 352 41.8 348.3C41 344.6 46.8 339.8 51.6 339.5C56.3 339.1 58.9 341.3 59.8 347.4C60.7 353.5 64.8 356.5 69.1 357.6C73.5 358.7 70.7 354.2 67.6 351.1C64.5 348 64.3 345.2 66.6 338.4C68.9 331.6 74.7 332.1 78.9 325.8C83.2 319.5 84 324.2 88.3 324.9C92.6 325.5 92 323.2 93.6 319.3C95.2 315.4 100.7 318.8 105.2 315.6C109.7 312.4 100.3 307.4 100.9 303.1C101.5 298.8 105.8 298.9 103.4 292.5C101 286 104.3 285.8 106 283.4C107.8 280.7 104.8 278.3 99.3 281.3ZM92.9 297C91.5 300.2 90.2 299.6 87.9 303.1C85.6 306.6 81.9 308.5 79.6 308.8C77.2 309.1 73.4 304.9 73.2 302.3C73 299.7 76.7 298.9 81 298.4C85.2 297.8 88.8 296 92.7 293C95.6 290.7 94.3 293.9 92.9 297Z" fill="#FF8400"/>
<path d="M235 362V362.3C233.3 363 231.7 364.3 230.8 366.4C228.4 372.3 228 369.7 222.3 373.7C216.7 377.7 218.8 380.8 219.1 384.8C219.4 388.8 212.9 393.3 209.7 394.8C206.5 396.2 205.1 405.4 206.1 409.8C207.1 414.2 201.8 416 198.6 420.1C195.3 424.1 193.5 430.9 191.4 434.2C189.3 437.5 185.4 439.4 181.6 438.8C177.7 438.1 174.2 431.8 172.1 426.2C170 420.7 169.3 414.6 170.5 408.5C171.7 402.5 171.3 403.2 169.8 393.8C168.4 384.4 161.7 381.6 158.1 380.7C154.5 379.8 148.5 381.9 145.2 380C142 378.1 140.6 375.4 138.5 366.5C136.4 357.6 140.8 355.7 144.2 350.9C147.6 346.1 154.4 341.1 160.4 339.5C166.4 337.9 170.6 339.4 174.3 341.3C178 343.2 179.7 347.3 182.4 347.6C185 347.9 187.3 346.5 189.9 347C192.5 347.5 194.3 348.8 196.9 348.4C199.5 348 197.8 345 197.5 343.4C197.1 341.8 194.8 342.2 191.7 342.2C188.6 342.2 187.8 341.3 186.7 339.3C185.6 337.2 183.8 339 182.3 338.2C180.8 337.4 181.1 341.1 181 342.6C180.9 344.1 179.7 341.5 179.7 341.5C179.7 341.5 178.4 343.5 177.9 342.6C177.4 341.7 178.6 339.5 178.4 337.2C178.2 334.9 174.9 335.5 172.9 333.7C170.9 332 168 331.7 165.2 332.6C162.4 333.5 161.9 332.9 160.7 335.3C159.5 337.8 153.4 339.4 151.5 339.1C149.6 338.8 150.9 334.9 152.6 331.4C154.2 327.9 157.6 329.1 159.7 325.6C161.8 322.1 158.9 321.6 157 321.1C155.1 320.5 154.5 318.7 156.5 314.3C158.5 309.9 161.2 311.1 162.3 313.1C163.4 315.2 165.9 316.2 168.5 316.2C171.1 316.2 170.5 314.4 170.4 310.6C170.4 306.8 172.1 304.8 175.5 303.3C178.9 301.8 184.7 299.5 186.9 297.4C189.1 295.3 193 296.7 195.1 298.9C197.2 301.1 201.7 302 206.6 302.8C208 303 209.1 302.5 210 301.7C224.1 318.1 233.2 338.9 235 362Z" fill="#FF8400"/>
<path d="M215.3 413.9C212.7 413.5 213 414.3 211.5 416.3C210 418.3 207.8 420.6 208.2 422.7C208.6 424.8 208.8 427.4 210.6 425.8C212.4 424.2 211.8 422.4 214.3 421.2C216.7 420.2 219.1 414.6 215.3 413.9Z" fill="#FF8400"/>
<path opacity="0.15" d="M101.2 422.9C49 380 50.7 296.9 96.4 270C94.5 270.6 92.5 271.3 90.6 272.1C36.5 293.7 10.2 355.2 31.9 409.2C40.2 429.9 54.4 446.6 71.7 458C99.5 476.4 135.7 481.2 169 467.9C196.3 457 216.5 435.9 227.1 410.7C212.4 431.2 153.4 465.7 101.2 422.9Z" fill="#00AF96"/>
<path opacity="0.4" d="M157.3 276.5C90.1 257.1 77.6 302.9 113.2 331.8C148.8 360.7 209.8 370.2 218 351.1C226.3 332.1 207.3 290.9 157.3 276.5Z" fill="white"/>
<path d="M600.8 100.7C610.6 125.2 598.7 153 574.2 162.8C559.1 168.8 542.7 166.6 530.1 158.3C522.2 153.1 515.8 145.6 512 136.2C502.2 111.7 514.1 83.9 538.6 74C548 70.2 557.9 69.7 567.1 71.8C581.9 75.3 594.8 85.6 600.8 100.7Z" fill="#FF8400"/>
<path opacity="0.15" d="M543.5 142.5C519.8 123.1 520.6 85.4 541.3 73.2C540.4 73.5 539.5 73.8 538.7 74.2C514.2 84 502.3 111.8 512.1 136.4C515.9 145.8 522.3 153.4 530.2 158.5C542.8 166.8 559.2 169 574.3 163C586.7 158 595.8 148.5 600.6 137.1C593.8 146.2 567.1 161.9 543.5 142.5Z" fill="#00AF96"/>
<path d="M523.8 105.4C525.291 105.4 526.5 104.191 526.5 102.7C526.5 101.209 525.291 100 523.8 100C522.309 100 521.1 101.209 521.1 102.7C521.1 104.191 522.309 105.4 523.8 105.4Z" fill="#FF9A00"/>
<path d="M567.5 135.9C569.212 135.9 570.6 134.512 570.6 132.8C570.6 131.088 569.212 129.7 567.5 129.7C565.788 129.7 564.4 131.088 564.4 132.8C564.4 134.512 565.788 135.9 567.5 135.9Z" fill="#FF9A00"/>
<path d="M579.3 129.7C583.056 129.7 586.1 126.656 586.1 122.9C586.1 119.145 583.056 116.1 579.3 116.1C575.544 116.1 572.5 119.145 572.5 122.9C572.5 126.656 575.544 129.7 579.3 129.7Z" fill="#FF9A00"/>
<path d="M526.5 145C527.881 145 529 143.881 529 142.5C529 141.119 527.881 140 526.5 140C525.119 140 524 141.119 524 142.5C524 143.881 525.119 145 526.5 145Z" fill="#FF9A00"/>
<path d="M542.6 105.4C545.582 105.4 548 102.982 548 100C548 97.0177 545.582 94.6 542.6 94.6C539.618 94.6 537.2 97.0177 537.2 100C537.2 102.982 539.618 105.4 542.6 105.4Z" fill="#FF9A00"/>
<path opacity="0.4" d="M569.8 74.8C545.5 67.8 540.9 84.4 553.8 94.8C566.7 105.2 588.8 108.7 591.8 101.8C594.8 94.9 587.9 80.1 569.8 74.8Z" fill="white"/>
<path d="M606.7 47.1L557 18.4V36.6L556.9 54.7L606.6 83.4L597.7 60.1L606.7 47.1Z" fill="#00E0C7"/>
<path d="M556.4 13.9V82.5" stroke="#E1E0E0" stroke-width="3.2022" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M579 37.2C576.1 35.5 574.6 36.9 574.6 39.7C574.6 39.7 574.6 40.2 574.6 43C574.6 44.2 575 45.2 575.6 46.1V50.3C573.6 50.1 569.4 50.5 568.8 50.5C568 50.6 567.8 50.9 567.8 52.1C567.8 52.6 567.8 54.7 567.8 54.7C567.8 55 568 55.4 568.3 55.5L589.6 68C589.9 68.2 590.1 68 590.1 67.7C590.1 67.7 590.1 65.5 590.1 65.1C590.1 63.9 589.9 63.3 589.1 62.4C588.5 61.6 584.4 56.5 582.3 54.3V50.1C582.9 49.9 583.3 49.4 583.3 48.2V44.9C583.3 42 581.9 38.9 579 37.2Z" fill="white"/>
<path d="M186.5 72.4C190.974 72.4 194.6 68.7735 194.6 64.3C194.6 59.8265 190.974 56.2 186.5 56.2C182.026 56.2 178.4 59.8265 178.4 64.3C178.4 68.7735 182.026 72.4 186.5 72.4Z" fill="#00E0C7"/>
<path d="M24.3 256C28.7735 256 32.4 252.374 32.4 247.9C32.4 243.427 28.7735 239.8 24.3 239.8C19.8265 239.8 16.2 243.427 16.2 247.9C16.2 252.374 19.8265 256 24.3 256Z" fill="#00E0C7"/>
<path d="M546.8 241.6C551.273 241.6 554.9 237.974 554.9 233.5C554.9 229.027 551.273 225.4 546.8 225.4C542.326 225.4 538.7 229.027 538.7 233.5C538.7 237.974 542.326 241.6 546.8 241.6Z" fill="#00E0C7"/>
<path d="M337.1 427.7C341.574 427.7 345.2 424.074 345.2 419.6C345.2 415.126 341.574 411.5 337.1 411.5C332.626 411.5 329 415.126 329 419.6C329 424.074 332.626 427.7 337.1 427.7Z" fill="#00E0C7"/>
<path d="M443.5 327.4C446.427 327.4 448.8 325.027 448.8 322.1C448.8 319.173 446.427 316.8 443.5 316.8C440.573 316.8 438.2 319.173 438.2 322.1C438.2 325.027 440.573 327.4 443.5 327.4Z" fill="#00E0C7"/>
<path d="M592.5 422.4C595.427 422.4 597.8 420.027 597.8 417.1C597.8 414.173 595.427 411.8 592.5 411.8C589.573 411.8 587.2 414.173 587.2 417.1C587.2 420.027 589.573 422.4 592.5 422.4Z" fill="#00E0C7"/>
<path d="M572.4 233.6C575.327 233.6 577.7 231.227 577.7 228.3C577.7 225.373 575.327 223 572.4 223C569.473 223 567.1 225.373 567.1 228.3C567.1 231.227 569.473 233.6 572.4 233.6Z" fill="#00E0C7"/>
<path d="M54.7 66.8C57.6271 66.8 60 64.4271 60 61.5C60 58.5729 57.6271 56.2 54.7 56.2C51.7729 56.2 49.4 58.5729 49.4 61.5C49.4 64.4271 51.7729 66.8 54.7 66.8Z" fill="#00E0C7"/>
<path d="M331.8 171.6C334.727 171.6 337.1 169.227 337.1 166.3C337.1 163.373 334.727 161 331.8 161C328.873 161 326.5 163.373 326.5 166.3C326.5 169.227 328.873 171.6 331.8 171.6Z" fill="#00E0C7"/>
<path d="M311.5 229.2C311.5 229.2 310.1 228.6 308.8 226.6C307.5 224.5 307.6 223.1 307.6 223.1L314.6 219.5L317.7 224.5L311.5 229.2Z" fill="#FFBC00"/>
<path d="M312.7 227.7C312.7 227.7 311.6 227.2 310.5 225.6C309.4 223.9 309.5 222.7 309.5 222.7L314.9 220L317.4 223.9L312.7 227.7Z" fill="#FF8400"/>
<path d="M309.879 223.999L309.372 224.32L311.079 227.026L311.587 226.706L309.879 223.999Z" fill="#FF8400"/>
<path d="M316.4 222.3C313.5 224.1 311 225.3 311 225.3C311 225.3 313.1 223.5 316 221.7L316.4 222.3Z" fill="#FFBC00"/>
<path d="M315.5 237.3C315.5 237.3 314.1 236.7 312.8 234.7C311.5 232.6 311.6 231.2 311.6 231.2L318.6 227.6L321.7 232.6L315.5 237.3Z" fill="#FFBC00"/>
<path d="M316.7 235.8C316.7 235.8 315.6 235.3 314.5 233.7C313.4 232 313.5 230.8 313.5 230.8L318.9 228.1L321.4 232L316.7 235.8Z" fill="#FF8400"/>
<path d="M313.883 232.116L313.376 232.436L315.083 235.143L315.59 234.823L313.883 232.116Z" fill="#FF8400"/>
<path d="M320.4 230.4C317.5 232.2 315 233.4 315 233.4C315 233.4 317.1 231.6 320 229.8L320.4 230.4Z" fill="#FFBC00"/>
<path d="M320.4 233.8L321 233.6L318.4 227.5L317.9 227.7L318.5 229.2L320.4 233.8Z" fill="#00AF96"/>
<path d="M313.9 220.9L317.8 226.4L320.9 233.7C320.9 233.7 361.1 210.8 372.7 202.7C372.7 202.7 382.4 197.1 390 190.8C397.6 184.4 396.1 183.1 396.1 183.1C396.1 183.1 395.6 181.4 393.5 181.4C390.4 180.9 379.9 183.6 379.9 183.6C379.9 183.6 375.5 182.2 374.9 182.6C373.2 183.5 313.9 220.9 313.9 220.9Z" fill="#00C9AC"/>
<path d="M328.8 218.7L326.2 213.1C326.2 213.1 326.1 212.7 324.5 212C322.9 211.3 301 206.6 300.6 206.6C300.2 206.5 299.8 206.6 299 206.7C298.2 206.8 297.4 207 297.4 207L293.4 209.2C293.4 209.2 292.8 209.4 292.8 210.3C292.8 211.2 293.5 211.4 293.5 211.4C293.5 211.4 307.9 220 308.3 219.9C308.7 219.8 312.2 219 312.2 219C312.2 219 313.9 223.7 316.6 227L328.8 218.7Z" fill="#00C9AC"/>
<path d="M315.8 238.6L320.9 233.7C320.9 233.7 319.2 232 326.6 227.8L328.9 229L321.2 236L315.8 238.6Z" fill="#00E0C7"/>
<path d="M393.5 187.3C391.8 186.4 377.4 195 377.4 195C377.4 195 362.8 206.7 355.9 211C349.1 215.3 326.6 227.8 326.6 227.8L327.1 228.8L321.5 232.6C321.5 232.6 322.3 233.9 322 234.6C321.4 235.8 315.7 238.7 315.7 238.7C315.7 238.7 328.5 232.8 334.2 229.1C395.8 188.9 393.5 187.3 393.5 187.3Z" fill="#00AF96"/>
<path d="M393.4 181.3C391.7 181 387.5 181.8 384.2 182.4L385.7 185.2L377.4 189.3C377.3 189.3 377.2 189.4 377.2 189.4C376.4 189.9 376.2 191 376.7 191.8C377.2 192.6 378.3 192.8 379.1 192.3L380.2 191.8L381.4 194.2L383.1 196C385.4 194.4 387.9 192.5 390.2 190.6C397.8 184.2 396.3 182.9 396.3 182.9C396.3 182.9 395.6 181.4 393.4 181.3Z" fill="#00AF96"/>
<path d="M379.2 190.3C379.6 190.9 379.4 191.8 378.8 192.2C378.2 192.6 377.3 192.4 376.9 191.8C376.5 191.2 376.7 190.3 377.3 189.9C378 189.4 378.8 189.6 379.2 190.3Z" fill="#ECECEC"/>
<path d="M378.6 190.7C378.8 191 378.7 191.4 378.4 191.6C378.1 191.8 377.7 191.7 377.5 191.4C377.3 191.1 377.4 190.7 377.7 190.5C378 190.3 378.4 190.4 378.6 190.7Z" fill="#C6C6C6"/>
<path d="M294 211.7L297 209.4L311.9 215.6C311.9 215.6 312.5 216.3 312 217.4C311.4 218.5 307.5 219.4 307.5 219.4L294 211.7Z" fill="#00AF96"/>
<path d="M296.9 210.1L294.4 212L307 219.3C308 219 310.4 218.3 310.8 217.5C311.3 216.5 310.8 215.8 310.8 215.8L296.9 210.1Z" fill="#00E0C7"/>
<path d="M326.8 214.3L326.2 213.1C326.2 213.1 326.1 212.7 324.5 212C322.9 211.3 301 206.6 300.6 206.6C300.2 206.5 299.8 206.6 299 206.7C298.2 206.8 297.4 207 297.4 207C297.4 207 322.5 212.5 326.8 214.3Z" fill="#00AF96"/>
<path d="M386.7 184.3L388.9 183.1C388.9 183.1 388.6 182 387.9 181.8C386.9 182 385.9 182.2 384.9 182.4L385.7 183.9L386.2 183.6L386.7 184.3Z" fill="#00E0C7"/>
<path d="M315.8 223.6V225.3L313 220.9L314.5 221.7L315.8 223.6Z" fill="#00AF96"/>
<path d="M315.707 220.996L314.607 221.69L315.835 223.635L316.934 222.941L315.707 220.996Z" fill="#00AF96"/>
<path d="M312.8 221.1L313 220.9L315.8 225.3L315.5 225.5L312.8 221.1Z" fill="#00AF96"/>
<path d="M379.2 183.3C378.6 183.1 377.8 182.9 377 182.7C377 182.7 374.9 184.5 374 184.6L374.8 185.9C374.8 185.8 377.2 186.3 379.2 183.3Z" fill="#00E0C7"/>
<path d="M339.7 209C340.3 208.6 340.8 208.4 341.4 208.5C342.1 208.6 342.5 209 342.7 209.4C343.2 210.2 343.2 211.3 341.9 212.2L341.3 212.6L342.9 215.1L342.3 215.5L338.5 209.7L339.7 209ZM341 211.9L341.5 211.6C342.3 211.1 342.5 210.4 342 209.8C341.4 208.9 340.5 209.4 340.1 209.6L339.6 209.9L341 211.9Z" fill="white"/>
<path d="M344.1 206.2C344.5 205.9 345.1 205.6 345.7 205.6C346.2 205.6 346.8 205.9 347.2 206.5C347.8 207.4 347.6 208.4 346.8 209L350.3 210.4L349.5 210.9L346.1 209.5L345.9 209.6L347.5 212.1L346.9 212.5L343.1 206.7L344.1 206.2ZM345.5 209L345.9 208.7C346.7 208.2 346.9 207.5 346.5 206.8C346.3 206.5 346 206.2 345.5 206.3C345.1 206.3 344.8 206.5 344.6 206.7L344.2 207L345.5 209Z" fill="white"/>
<path d="M351.3 202.3L348.9 203.8L350 205.5L352.3 204L352.7 204.6L350.4 206.1L351.9 208.3L354.3 206.8L354.7 207.4L351.7 209.3L347.9 203.5L350.9 201.6L351.3 202.3Z" fill="white"/>
<path d="M353.3 200.2L356.5 205.1L358.2 204L358.7 204.8L356.1 206.5L352.3 200.7L353.3 200.2Z" fill="white"/>
<path d="M360.1 196.9L357.8 198.4L358.8 199.9L361 198.4L361.5 199.2L359.3 200.7L360.5 202.5L362.8 201L363.3 201.8L360.1 203.9L356.3 198.1L359.5 196L360.1 196.9Z" fill="white"/>
<path d="M367.1 197.4L365 198.8L365.4 200.5L364.6 201L363.1 193.3L369.5 197.8L368.7 198.3L367.1 197.4ZM366.2 196.8L364.2 195.3L364.7 197.8L366.2 196.8Z" fill="white"/>
<path d="M367.7 190.8C368.4 190.3 369.1 190 369.9 190.1C370.9 190.2 371.8 190.8 372.4 191.8C373 192.8 373.2 193.8 372.9 194.8C372.6 195.7 372.1 196.1 371.4 196.6L370.4 197.3L366.6 191.5L367.7 190.8ZM370.6 195.9L370.9 195.7C371.3 195.5 371.8 195.1 372 194.4C372.1 193.9 372.1 193.1 371.6 192.3C371.1 191.5 370.4 191.1 369.8 191.1C369.1 191 368.6 191.3 368.2 191.6L367.9 191.8L370.6 195.9Z" fill="white"/>
<path d="M329.4 213.3C326.9 214.9 326.3 218.2 327.9 220.7C329.5 223.2 332.8 223.8 335.3 222.2C337.8 220.6 338.4 217.3 336.8 214.8C335.1 212.4 331.8 211.7 329.4 213.3ZM335.2 221.4C334.7 221.8 334 222.1 333.4 222.3C333.2 222.3 333.1 222.4 332.9 222.4C331.4 222.3 331.6 220.4 331.9 219C332 218.3 331.6 217.7 330.9 217.5C329.5 217.2 327.7 216.6 328.2 215.2C328.3 215 328.4 214.9 328.5 214.8C328.8 214.4 329.2 214 329.7 213.7C332 212.2 335.1 212.9 336.4 215.4C337.6 217.4 337 220 335.2 221.4Z" fill="white"/>
<path d="M186.8 339.2L496.6 138.7" stroke="#FF8400" stroke-width="3.202" stroke-miterlimit="10" stroke-linecap="round" stroke-dasharray="12.81 12.81"/>
<path d="M494.8 155.4L491.7 154.6L495.6 139.3L480 136.6L480.6 133.4L499.5 136.8L494.8 155.4Z" fill="#FF8400"/>
`
  
}