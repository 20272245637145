import React from 'react'
import { motion, useTransform } from 'framer-motion'

import { useScrollProgress, useViewedGAEvent } from 'src/hooks'

import ScrollBlock from 'src/components/scroll-block'
import Illus from './illus'

import s from './style.module.css'

export const Feature = ({ feature : { primary } }) => {
  const { ref, progress, raw } = useScrollProgress()
  const fadeOut = useTransform(progress, [0.3,1], [1,0])
  useViewedGAEvent(raw, primary.title.text)
  
  return <ScrollBlock>
    <motion.div style={{ opacity: fadeOut }} className={s['feature']}>
      <div ref={ref} className={s['imgContainer']}>
        <Illus source={primary.illustration} progress={progress} raw={raw} />
      </div>
      <div>
        <div className={s['featureContent']}>
          <h3>{primary.title.text}</h3>
          <h4>{primary.subtitle.text}</h4>
          <hr />
          <div dangerouslySetInnerHTML={{__html: primary.content.html}} />
        </div>
      </div>
    </motion.div>
  </ScrollBlock>
}

const Features = ({ features }) => {
  
  return <div className={s['features']}>
    {features.map((feature, i) => (
      <Feature key={i} feature={feature} />
    ))}
  </div>
}

export default Features