import React, { Fragment } from 'react'
import { Link } from 'gatsby';

import { Button } from 'src/styled-components';

import s from './style.module.css'

const Pilar = ({ pilar }) => {
  return <Fragment>
    <header><h4>{pilar.data.title.text}</h4></header>
    <div className={s['pilarContent']}>
      <div dangerouslySetInnerHTML={{ __html: pilar.data.description.html}} />
      <Button Component={Link} to={`/${pilar.uid}`}>En savoir +</Button>
    </div>
  </Fragment>
}

const Pilars = ({ pilars }) => {
  return <div>
    <div className={s['pilars']}>
      {pilars.map((pilar, i) => (
        <Pilar key={i} pilar={pilar.pilar_id.document} />
      ))}
    </div>
  </div>
}

export default Pilars