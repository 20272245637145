import React from 'react';
import { usePrismicHomepage } from "src/utils/prismic";

import Seo from 'src/components/seo';
import ScrollBlock from 'src/components/scroll-block'

import Hero from './hero';
import Promises from './promises'
import Features from './features'
import Pilars from './pilars'
import Testimonials from './testimonials'
import Plans from './plans'
import s from './style.module.css';

const Anchor = ({id, children, ...p}) => (
  <h2 {...p}>{children}
    <span id={id} className={s['anchor']} />
  </h2>
);

const Home = () => {
  const { punchline, meta_description, promises, features, pilars, testimonials, plans, ...node } = usePrismicHomepage();
  
  return <div>
    <Seo title={punchline.text} description={meta_description.text} />
    <div className={s['home']}>
      <Hero punchline={punchline.text} />
      <span id="start" className={s['anchor']} />
      <Promises promises={promises} />
      
      <Anchor id="features">{node.feat_title.text}</Anchor>
      <h3>{node.feat_subtitle.text}</h3>
      <Features features={features} />
      
      
      <Anchor id="guides">{node.pilar_title.text}</Anchor>
      <h3>{node.pilar_subtitle.text}</h3>
      <Pilars pilars={pilars} />
      
      <h2>{node.testimonials_title.text}</h2>
      <h3>{node.testimonials_subtitle.text}</h3>
      <Testimonials testimonials={testimonials} />
      
      
      <Anchor id="price"></Anchor>
      <ScrollBlock height="120vh">
        <h2>{node.plans_title.text}</h2>
        <h3>{node.plans_subtitle.text}</h3>
        <div className={s['intro']} dangerouslySetInnerHTML={{ __html: node.plans_intro.html }} />
        <Plans plans={plans} />
      </ScrollBlock>
      
    </div>
  </div>
}

export default Home