import React from 'react';

import DelayedSvg from '../delayed-svg'
import layers from './layers'

const EarthMoon = ({ source, raw }) => {
  return <div>
    <DelayedSvg raw={raw} width="100%" height="25em" viewBox={`0 -80 640 500`} preserveAspectRatio="xMidYMid meet">
      {() => (
        <g dangerouslySetInnerHTML={{ __html: layers[source] }} />
      )}
    </DelayedSvg>
  </div>
}

export default EarthMoon