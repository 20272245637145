import React from 'react'

import NoImage from './no-image'

import Cockpit360 from './cockpit-360'
import EarthMoon from './earth-moon'
import ConnectedApi from './connected-api'
import Static from './static'
import Automation from './automation'

const Components = {
  '360': Cockpit360,
  'earth-moon': EarthMoon,
  'connected-api': ConnectedApi,
  'keynote': Static,
  'contacts-human': Static,
  'contacts-machine': Automation,
  'dashboard': Static,
  'cockpit': Static,
  'timeline': Static,
}

const Illus = p => {
  const Component = Components[p.source]
  
  if (!Component) return <NoImage />
  
  return <Component {...p} />
}

export default Illus