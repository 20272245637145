import React from 'react'
import { motion, useTransform } from 'framer-motion'

import { useScrollProgress, useViewedGAEvent } from 'src/hooks'

import ScrollBlock from 'src/components/scroll-block'
import Illus from './illus'

import s from './style.module.css'

const Promise = ({ promise: { primary: { title, illustration } , items  }}) => {
  const { ref, progress, raw } = useScrollProgress()
  const fadeOut = useTransform(progress, [0.3,1], [1,0])
  useViewedGAEvent(raw, title.text)
  
  return <ScrollBlock>
    <motion.div style={{ opacity: fadeOut }} className={s['promise']}>
      <div ref={ref} className={s['imgContainer']}>
        <Illus source={illustration} progress={progress} raw={raw} />
      </div>
      <div>
        <div className={s['promiseContent']}>
          <h2>{title.text}</h2>
          <ol>
            { items.map((item,i) => (
              <li key={i}>
                <strong>{item.promise.text}</strong><br />
                {item.description.text}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </motion.div>
  </ScrollBlock>
}

const Promises = ({ promises }) => {
  
  return <div>
    {promises.map((promise, i) => (
      <Promise key={i} promise={promise} />
    ))}
  </div>
}

export default Promises