import { useRef, useEffect } from 'react'

const VIEWED_STEPS = [1,3,5,10]

const useViewedGAEvent = (progress, title) => {
  const t = useRef({
    from: null,
    counter: 0,
    nextViewTarget: 0,
  })
  
  useEffect(() => {
    let to = null
    
    const sendViewed = () => {
      const { nextViewTarget } = t.current
      const time = VIEWED_STEPS[nextViewTarget]
      
      window.gtag && window.gtag('event', `Viewed <${title}> for ${time}s`, {
        'event_category': 'BlockView',
        'event_label': `${title}`,
      })
      
      if (VIEWED_STEPS[nextViewTarget+1]) {
        const waitNext = VIEWED_STEPS[nextViewTarget+1] - VIEWED_STEPS[nextViewTarget]
        to = setTimeout(sendViewed, waitNext * 1000)
        t.current.nextViewTarget = nextViewTarget + 1
      } else {
        to = null
        t.current.nextViewTarget = null
      }
    }
    
    let unsub = progress.onChange(scroll => {
      const { from, counter, nextViewTarget } = t.current
      if (scroll > -0.5 && scroll < 0.5) {
        if (!from && nextViewTarget !== null) {
          t.current.from = Date.now()
          to = setTimeout(sendViewed, VIEWED_STEPS[nextViewTarget]*1000 - counter)
        }
      } else if (from) {
        t.current.counter += Date.now() - from
        t.current.from = null
        
        if (to) clearTimeout(to)
      }
    })
    
    return () => {
      unsub && unsub()
      to && clearTimeout(to)
    }
  }, [ progress, t, title ])
}

export default useViewedGAEvent