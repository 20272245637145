import { useRef, useState, useEffect } from 'react'
import { useViewportScroll, useTransform, useSpring } from 'framer-motion'

import { useScreenSize } from 'src/hooks'

const interpolate = (x, y, { clamp = true } = {}) => {
  const a = (y[1] - y[0]) / (x[1] - x[0]);
  const b = y[0] - a * x[0];
  
  if (clamp) {
    const min = Math.min(y[0], y[1]);
    const max = Math.max(y[0], y[1]);
    
    return v => Math.max(Math.min(a * v + b, max), min);
  }
  
  return v => a*v + b
}

const useScrollProgress = ({
  spring = { stiffness: 800, damping: 40 },
} = {}) => {
  const ref = useRef()
  const { scrollY } = useViewportScroll();
  const size = useScreenSize();
  const [ context ] = useState({ interpolate: () => 0 });
  
  useEffect(() => {
    const setContext = () => {
      try {
        const sticky = ref.current.offsetParent
        const area = sticky.parentNode
        const node = ref.current
        
        const [W,H] = size
        const start = node.offsetTop + area.offsetTop - H
        
        let centered, has_left, start_leave
        if (W > area.clientWidth) {
          centered = area.offsetTop - 100
          
          has_left = centered + area.clientHeight
          start_leave = has_left - sticky.clientHeight
        } else {
          start_leave = area.offsetTop - 100
          centered = (start_leave + start) / 2 + node.clientHeight/2
          
          has_left = start_leave + node.clientHeight
        }
        let x = [ start, centered ];
        let y = [-1, 0];
        const entering = interpolate(x,y)
        x = [start_leave, has_left]
        y = [0, 1]
        const leaving = interpolate(x,y)
        
        context.interpolate = v => {
          if (v<=start) return -1;
          if (v<centered) return entering(v);
          if (v<=start_leave) return 0;
          if (v<has_left) return leaving(v);
          return 1
        }
      } catch (e) { }
    }
    
    setTimeout(setContext, 100)
  }, [ref, context, size]);
  
  const raw = useTransform(scrollY, v => context.interpolate(v))
  
  return {
    ref, 
    progress : useSpring(raw, spring),
    raw
  }
}

export default useScrollProgress