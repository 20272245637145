import clsx from 'clsx'
import React, { Fragment, useState, useCallback, useRef, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { motion, AnimatePresence, useViewportScroll, useTransform } from 'framer-motion'

import { FillAbs, Button, Logo } from 'src/styled-components'
import askDemo from 'src/utils/ask-demo'
import ModalSrv from 'src/utils/modal'

import WheelWarning from './wheel-warning'
import s from './desktop.module.css'
import rs from '../hero.module.css'

const MOBILE_LIMIT = 1000

const Desktop = ({ punchline }) => {
  const loadedIFrame = useRef(null)
  const [ state, setState ] = useState('closed')
  const { scrollY } = useViewportScroll();
  const isTop = useTransform(scrollY, y => y === 0 ? 'auto' : 'none')
  
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "simple-map.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  useEffect(() => {
    const handleMessage = message => {
      try {
        if (message.data === 'ready') {
          if (loadedIFrame.current) loadedIFrame.current()
        } else if (message.data.params.contact) {
          askDemo({ context: 'action' })
          window.gtag && window.gtag('event', 'Clic sur Cockpit/Action', {
            'event_category': 'Nav',
            'event_label': "Clic sur une action du cockpit",
          })
        } else {
          askDemo({ context: 'prospection' })
          window.gtag && window.gtag('event', 'Clic sur Cockpit/Prospection', {
            'event_category': 'Nav',
            'event_label': "Clic sur Prospection du cockpit",
          })
        }
      } catch (e) { }
    }
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [ loadedIFrame ])

  const live = useCallback(() => {
    if (window.innerWidth > MOBILE_LIMIT) {
      window.gtag && window.gtag('event', 'Cockpit interactif', {
        'event_category': 'Nav',
        'event_label': "Arrivée sur cockpit interractif",
      })
      setState('live')
      ModalSrv(<WheelWarning />)
    }
  }, [ setState ])
  
  const trackArrow = useCallback(() => {
    window.gtag && window.gtag('event', 'Voir plus !', {
      'event_category': 'Nav',
      'event_label': "Clic sur Voir plus en démo",
    })
  }, [ ])
  
  
  const launch = useCallback(() => {
    
    setState(s => {
      if (s!=='closed') return s
      
      if (window.innerWidth > MOBILE_LIMIT) {
        window.gtag && window.gtag('event', 'Voir le cockpit', {
          'event_category': 'Nav',
          'event_label': "Clic sur Voir le cockpit du commercial",
        })
        
        setTimeout(() => setState('load'), 3000)
        Promise.all([
          new Promise((res, rej) => loadedIFrame.current = res),
          new Promise((res, rej) => setTimeout(res, 3000)),
        ]).then(() => {
          setState('ready')
        })
      } else {
        window.gtag && window.gtag('event', 'Voir le cockpit (mobile)', {
          'event_category': 'Nav',
          'event_label': "Clic sur Voir le cockpit du commercial (mobile)",
        })
      }
      
      return 'anim'
    })
  }, [ setState, loadedIFrame ])
  
  
  const handleScrollTop = useCallback(() => {
    const scrollPos = scrollY.get()
    if (scrollPos !== 0) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }, [ scrollY ])
  
  return <Fragment>
    <FillAbs
      Component={motion.div}
      initial="closed"
      animate={state}
      variants={v.container}
      className={clsx(s['container'], state==='closed' && s['startup'])}
      onClick={handleScrollTop}
    >
      
      { ['load', 'ready', 'live'].includes(state) && (
        <FillAbs
          Component={motion.iframe}
          variants={v.iframe}
          src="https://app.prelead.fr/actions-demo"
          style={{
            width: '100%',
            height: '100%',
            border: "none",
            zIndex: state==='live' ? 10 : 0,
            pointerEvents: isTop,
          }}
        />
      )}
      
      
      <Img
        fluid={data.file.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        alt="map"
        className={s['map']}
      />
      
      {state !== 'closed' && <Cockpit />}
      
    </FillAbs>
    
    <AnimatePresence>
      { state === 'closed' ? (
        <motion.div key="intro" className={rs['heroContent']} initial={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Logo height="1.8em" />
          <h1>{punchline}</h1>
          <Button className={rs['demo']} onClick={launch}>
            Découvrir le cockpit des commerciaux
          </Button>
        </motion.div>
      ) : (
        <motion.div
          key="bottom"
          className={s['bottomNav']}
          initial={{ opacity: 0, y: '100%' }}
          animate={{ opacity: 1, y: '0%' }}
          exit={{ opacity: 0 }}
        >
          <FillAbs Component={Link} to="/#start" onClick={trackArrow}>
            <span />
          </FillAbs>
          
          <p>Vous pouvez en voir plus sur un PC !</p>
          <Button className={rs['demo']} onClick={() => askDemo('mobile')}>
            Ou demander une démo !
          </Button>
        </motion.div>
      )}
      
      { state === 'ready' && (
        <motion.div
          className={s['goLive']}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Button onClick={live}>
            Voir en vrai...!
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  </Fragment>
}

export default Desktop

const v = {
  container: {
    closed:{
      filter: 'blur(5px)',
      opacity: 0.7,
    },
    anim: {
      filter: 'blur(0px)',
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  },
  actionContainer: {
    closed: {
      translateX: '100%',
    },
    anim: {
      translateX: '0%',
      transition: {
        staggerChildren: 0.05,
        delayChildren: 2.7,
        type: "spring",
        stiffness: 400,
        damping: 30,
        delay: 2.4,
      },
    }
  },
  action: {
    closed: {
      opacity: 0,
      translateY: '1em',
    },
    anim: {
      opacity: 1,
      translateY: '0em',
    }
  },
  campaigns: {
    closed: {
      opacity: 0,
      translateY: '1em',
    },
    anim: {
      opacity: 1,
      translateY: '0em',
      transition: {
        staggerChildren: 0.07,
        delayChildren: 1.7,
        delay: 1.5
      }
    }
  },
  dotContainer: {
    anim: {
      transition: {
        staggerChildren: 0.07,
        delayChildren: .8,
      }
    }
  },
  dot: {
    closed: {
      scale: 0,
      opacity: 0,
    },
    anim: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 15,
      },
    }
  },
  iframe: {
    closed: {
      opacity: 0,
    },
    anim: {
      opacity: 0,
    },
    load: {
      opacity: 0,
    },
    ready: {
      opacity: 0,
    },
    live: {
      opacity: 1,
      transition: {
        duration: 1
      }
    }
  },
}


const dots = [
  [47,31],
  [43,51],
  [34,41],
  [56,55],
  [60,41],
  [45,68],
  [48,44],
  [58,69],
]

const Cockpit = React.memo(() => {
  return <Fragment>
    <FillAbs Component={motion.div} className={s['actions']} variants={v.actionContainer}>
      <header>Actions Programmées</header>
      { new Array(7).fill(true).map((_,i) => (
        <motion.div key={i} className={i===0 ? s['date'] : undefined} variants={v.action}>
          <div>
            <div />
          </div>
          <div>
            <div style={{ width: `${30+Math.floor(Math.random()*10)}%`}} />
            <div />
            <div style={{ width: `${40+Math.floor(Math.random()*20)}%`}} />
          </div>
        </motion.div>
      ))}
    </FillAbs>
    
    <motion.div className={s['campaigns']} variants={v.campaigns}>
      <header>Prospection</header>
      <div>
        <motion.div variants={v.action} style={{ width: `${30+Math.floor(Math.random()*10)}%`}} />
        <div />
        <motion.div variants={v.action} style={{ width: `${40+Math.floor(Math.random()*20)}%`}} />
        <div />
        <motion.div variants={v.action} style={{ width: `${40+Math.floor(Math.random()*20)}%`}} />
        <div />
        <motion.div variants={v.action} style={{ width: `${40+Math.floor(Math.random()*20)}%`}} />
      </div>
    </motion.div>
    
    <motion.div variants={v.dotContainer}>
      { dots.map(([x,y],i) => (
        <motion.div key={i} className={s['dot']} variants={v.dot} style={{left:`${x}%`,top:`${y}%`}} />
      ))}
    </motion.div>
  </Fragment>
})
