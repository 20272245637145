import React, { useEffect, useState, useMemo } from 'react';
import { motion, useAnimation } from 'framer-motion'

import layers from './layers'

const TEMPO = 0.9;
const ACTIONS = layers.actions;
const SORT_COLORS = ["#00C9AC", "#FFBC00", "#E1E0E0"]

const Automation = ({ raw }) => {
  const [ state, setState ] = useState({
    out: 1,
    current: 1,
    action: 0
  });
  const progress = useAnimation()
  
  useEffect(() => {
    let mounted = true;
    const start = () => progress.start(custom => ({
      transition: { duration: TEMPO, ease: 'easeIn' },
      ...custom,
    })).then(() => {
      if (mounted) {
        const r = Math.random()
        const r2 = Math.random()
        setState(s => ({
          current: r > 0.4 ? 0 : (r > 0.2 ? 1 : 2),
          out: s.current,
          action: r2 > 0.666 ? 0 : (r2 > 0.333 ? 1 : 2),
        }))
        start()
      }
    })
    
    start()
    return () => mounted = false
  }, [setState, progress])
  
  return <div>
    <svg raw={raw} width="100%" height="25em" viewBox={`0 0 640 500`} preserveAspectRatio="xMidYMid meet">
      <motion.g
        style={{
          transform: 'rotate(30deg) skewX(-30deg) scaleY(0.866)',
          transformOrigin: '0px 0px'
        }}
      >
        <motion.g style={{ translateX:35, translateY: 35}}>
          <Line color="#E1E0E0" animate={progress} count={7} />
        </motion.g>
        <motion.g style={{ translateX: 418 }}>
          <motion.g>
            <Line color={SORT_COLORS[0]} animate={state.out === 0 ? progress : {}} />
          </motion.g>
          <motion.g style={{ translateY: 35}}>
            <Line color={SORT_COLORS[1]} animate={state.out === 1 ? progress : {}} />
          </motion.g>
          <motion.g style={{ translateY: 70}}>
            <Line color={SORT_COLORS[2]} animate={state.out === 2 ? progress : {}} />
          </motion.g>
        </motion.g>
      </motion.g>
      <g dangerouslySetInnerHTML={{ __html: layers.all }} />
      <Heart state={state} animate={progress} />
    </svg>
  </div>
}

export default Automation


const Card = ({ color="#00C9AC", animColor=false, animate, index=0 }) => {
  return <motion.g
    style={{
      translateX: index*35
    }}
  >
    {animColor ? (
      <motion.rect
        initial={false}
        custom={animColor}
        animate={animate}
        x={-14} y={-14} width={28} height={28} rx={5} ry={5} 
      />
    ) : (
      <rect x={-14} y={-14} width={28} height={28} rx={5} ry={5} fill={color} />
    )}
    <motion.g 
      style={{
        transform: `translate(-27px, -1px) scaleY(${1/0.866}) skewX(30deg) rotate(-30deg)`,
        transformOrigin: '0px 0px',
      }}
      dangerouslySetInnerHTML={{ __html: layers.contact }}
    />
  </motion.g>
}

const Line = ({ color="#00C9AC", count=5, animate }) => {
  const mapper = useMemo(() => new Array(count).fill(true), [ count ]);
  
  return <motion.g
    custom={{ translateX: [0,35] }}
    initial={false}
    animate={animate}
  >
    <motion.g custom={{ opacity: [0,1] }} initial={false} animate={animate}>
      <Card color={color} index={0} />
    </motion.g>
    {mapper.map((_,i) => (
      <Card key={i+1} color={color} index={i+1} />
    ))}
    <motion.g custom={{ opacity: [1,0] }} initial={false} animate={animate}>
      <Card color={color} index={mapper.length+1} />
    </motion.g>
  </motion.g>
}

const Heart = ({ animate, state: { current, out, action } }) => {
  return <motion.g>
    <motion.g
      custom={{
        opacity: [1,0,0],
        scale: [0.2,2.5,2.5],
        translateY: [0,-100,-100],
        transition: {
          duration: TEMPO,
          times: [0,0.5,1]
        }
      }}
      animate={animate}
    >
      <motion.g
        style={{
          transform: 'translate(310px,150px) scaleY(0.866) skewY(-30deg)',
          transformOrigin: '0px 0px'
        }}
        dangerouslySetInnerHTML={{ __html: ACTIONS[action] }}
      />
    </motion.g>
    <motion.g style={{ translateX: 305, translateY:180, scale:1.4 }}>
      <motion.g
        style={{
          transform: 'rotate(30deg) skewX(-30deg) scaleY(0.866) translateY(-10px)',
          transformOrigin: '0px 0px'
        }}
      >
        <clipPath id="heart_content">
          <rect id="heart_clip" x="-14" y="-14" width="28" height="28" fill="none" />
        </clipPath>
        <g clipPath="url(#heart_content)">
          <motion.g
            custom={{ translateX: [0,35] }}
            initial={false}
            animate={animate}
          >
            <Card
              animColor={{
                fill: [SORT_COLORS[2], SORT_COLORS[2], SORT_COLORS[current]],
                transition: {
                  times: [0,0.8,1],
                  duration: TEMPO,
                }
              }}
              animate={animate}
              index={-1}
            />
            <Card color={SORT_COLORS[out]} index={0} />
          </motion.g>
        </g>
      </motion.g>
    </motion.g>
  </motion.g>
}