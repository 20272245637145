import React from 'react'

import Desktop from './desktop'

import s from './hero.module.css'

const Hero = ({ punchline }) => {
  return <div>
    <div className={s['hero'] + ' ' + s['heroesktop']}>
      <Desktop punchline={punchline} />
    </div>
  </div>
}

export default Hero