import React from 'react'

import { useScrollProgress, useViewedGAEvent } from 'src/hooks'
import { Button } from 'src/styled-components';
import askDemo from 'src/utils/ask-demo'

import s from './style.module.css'

const Plan = ({ plan }) => {
  const unit = Math.floor(plan.daily_price)
  let decimals = '' + Math.floor((plan.daily_price - unit) * 100)
  if (decimals.length === 1) decimals = '0' + decimals;
  
  return <div className={s['plan']}>
    <div className={s['price']}>
      <header>
        <span>{unit}</span>
        <sup className={s['decimals']}>,{decimals}</sup>
        <span>€</span>
        <small>par jour<sup>*</sup></small>
      </header>
    </div>
    <header>{plan.version.text}</header>
    <div className={s['content']} dangerouslySetInnerHTML={{ __html: plan.plan_features.html}} />
    {plan.available === 'available' ? (
      <Button className={s['btn']} onClick={askDemo}>Découvrez !</Button>
    ) : (
      <Button disabled className={s['comingSoon']}>Bientôt disponible !</Button>
    )}
  </div>
}

const Plans = ({ plans }) => {
  const { ref, raw } = useScrollProgress()
  useViewedGAEvent(raw, 'Prix')
  
  return <div ref={ref} className={s['plansContainer']}>
    <div className={s['plans']}>
      {plans.map((plan, i) => (
        <Plan key={i} plan={plan} />
      ))}
    </div>
    <small>* : Prix par utilisateur avec facture globale tous les mois</small>
  </div>
}

export default Plans