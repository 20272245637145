import { graphql, useStaticQuery } from 'gatsby';

export const usePrismicHomepage = () => {
  const { prismicHomepage } = useStaticQuery(graphql`
    query {
      prismicHomepage {
        data {
          punchline { text }
          meta_description { text }
          promises {
            ... on PrismicHomepagePromisesPromise {
              primary {
                title {text }
                illustration
              }
              items {
                promise {text}
                description {text }
              }
            }
          }
          feat_title { text }
          feat_subtitle { text }
          features {
            ... on PrismicHomepageFeaturesFeature {
              primary {
                title { text }
                subtitle { text }
                content { html }
                illustration
              }
            }
          }
          pilar_title { text }
          pilar_subtitle { text }
          pilars {
            pilar_id {
              document {
                ... on PrismicPilar {
                  uid
                  data {
                    title { text }
                    description { html }
                  }
                }
              }
            }
          }
          testimonials_title { text }
          testimonials_subtitle { text }
          testimonials {
            name { text }
            company { text }
            content { html }
            image {
              localFile {
                childImageSharp {
                  fixed(width: 125, height: 125) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          plans_title { text }
          plans_subtitle { text }
          plans_intro { html }
          plans {
            daily_price
            available
            version { text }
            plan_features { html }
          }
        }
      }
    }
  `);
  
  const node = prismicHomepage.data;
  if (!node) return null;
  
  return node;
}