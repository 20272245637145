import React, { useLayoutEffect, useRef, useState } from 'react'

import { useScreenSize } from 'src/hooks'
import s from './scroll-block.module.css'

const NAV_HEIGHT = 100;

const Block = ({ children, height=`100vh` }) => {
  const ref = useRef()
  const size = useScreenSize();
  
  const [ offsetTop, setOffsetTop ] = useState(0)
  
  useLayoutEffect(() => {
    const H = size[1]
    const node = ref.current.firstChild
    let offsetTop = Math.max((H - NAV_HEIGHT - node.clientHeight) / 2, 0);
    
    if (offsetTop < 0.1*H) offsetTop=0
    
    setOffsetTop(offsetTop)
  }, [ ref, height, size, setOffsetTop ])
  
  
  return <div className={`scrollBlock ${s['container']}`} style={{
    minHeight: offsetTop ? `calc(${offsetTop}px + ${height})` : 0,
    marginTop: -offsetTop,
  }}>
    <div ref={ref} className={s['childrenContainer']} style={{
      paddingTop: offsetTop,
    }}>
      <div>
        {children}
      </div>
    </div>
  </div>
}

export default Block;