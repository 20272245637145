import React from 'react'
import Img from "gatsby-image/withIEPolyfill"

import s from './style.module.css'

const Testimonial = ({ testimonial }) => {
  return <div className={s['testimonial']}>
    <div className={s['imgContainer']}>
      <Img
        alt={testimonial.image.alt}
        fixed={testimonial.image.localFile.childImageSharp.fixed}
      />
    </div>
    <div className={s['header']}>{testimonial.name.text}</div>
    <div className={s['company']}>{testimonial.company.text}</div>
    <div dangerouslySetInnerHTML={{ __html: testimonial.content.html}} />
  </div>
}

const Testimonials = ({ testimonials }) => {
  return <div>
    <div className={s['testimonials']}>
      {testimonials.map((testimonial, i) => (
        <Testimonial key={i} testimonial={testimonial} />
      ))}
    </div>
  </div>
}

export default Testimonials