export default {
  contact: '<path d="M29.7 9.99986C27.9 8.99986 25.8 9.09986 24.3 9.99986C24.3 9.99986 24 10.1999 22.5 10.9999C21.9 11.3999 21.7 11.6999 21.8 12.0999L19.6 13.3999C17.9 12.9999 13.8 12.3998 13.2 12.2998C12.4 12.1998 12.1 12.2999 11.4 12.5999C11.1 12.7999 10 13.3999 10 13.3999C9.80001 13.4999 9.80001 13.6999 10 13.6999L23.2 21.2998C23.4 21.3998 23.6 21.3998 23.8 21.2998C23.8 21.2998 24.9 20.5999 25.2 20.4999C25.8 20.0999 26 19.8999 25.8 19.4999C25.6 19.0999 24.6 16.7998 23.8 15.7998L26.1 14.4999C26.7 14.5999 27.4 14.3999 28 14.0999L29.8 13.0999C31.2 12.1999 31.5 10.9999 29.7 9.99986Z" fill="#FAFBFB"/>',
  actions: [`
<path d="M27.7 17.2001L29.4 16.7001L30.3 18.5001L29.2 18.9001L29.1 40.3001L30.3 42.4001L29.3 43.0001L28.1 40.9001L3.29999 26.6001L2.09999 27.3001L1.09999 25.6001L2.29999 24.9001L2.39998 3.50009L1.29999 1.80009L2.19998 1.00009L3.79999 3.40009L27.7 17.2001ZM30.5 17.2001L0.799988 0.100098L0.699982 26.7001L30.4 43.8001L30.5 17.2001Z" fill="#FFBC00"/>
<path d="M29.3 16.7001L27.7 17.2001L15.6 20.9001L3.59998 3.30016L1.89996 0.900146L1.09998 1.70015L2.19997 3.40015L12.8 18.9001L13.4 18.5002L14.4 20.3002L13.9 20.5002L15.6 23.0002L17.3 22.4001L16.8 21.7001L17.8 21.1002L18.4 22.1002L29 18.8002L30.2 18.5002L29.3 16.7001Z" fill="#FFBC00"/>
<path d="M17.8 21.1001L16.8 21.7001L17.3 22.4001L27.9 40.8001L29.2 42.9001L30.2 42.3001L29 40.2001L18.4 22.1001L17.8 21.1001Z" fill="#FFBC00"/>
<path d="M13.4 18.5L12.8 18.9L2.19997 24.7L0.899963 25.4L1.89996 27.1L3.19997 26.5L13.9 20.5L14.4 20.3L13.4 18.5Z" fill="#FFBC00"/>
`,`
<path d="M0.399994 4.7998L0.299988 27.1998C0.299988 29.5998 2.00001 32.4998 4.10001 33.6998L26.3 46.4998C28.4 47.6998 30.1 46.6998 30.1 44.2998L30.2 23.4998L24.5 20.1998L28.8 22.6998V27.3998L29.5 27.7998V29.3998L28.8 28.9998V33.6998L29.5 34.0998V35.6998L28.8 35.2998V39.9998L29.5 40.3998V41.9998L28.8 41.5998V43.4998C28.8 44.9998 27.7 45.5998 26.4 44.7998L24.5 43.6998V44.4998L23.1 43.6998V42.8998L18.8 40.3998V41.1998L17.4 40.3998V39.5998L13.1 37.0998V37.8998L11.7 37.0998V36.2998L7.39999 33.7998V34.5998L6 33.9998V33.1998L4.10001 32.0998C2.80001 31.2998 1.69999 29.4998 1.79999 27.9998V26.0998L1.10001 25.6998V24.0998L1.79999 24.4998V19.7998L1.10001 19.3998V17.7998L1.79999 18.1998V13.4998L1.10001 13.0998V11.4998L1.79999 11.8998V7.1998L6.10001 9.6998L0.399994 6.3998V4.7998Z" fill="#FFBC00"/>
<path d="M7.5 29.3L6.10001 28.5V33.2V34L7.5 34.8V34V29.3ZM7.5 23L6.10001 22.2V26.8999L7.5 27.7V23ZM7.5 16.8L6.10001 16V20.7L7.5 21.5V16.8ZM7.5 10.5L6.10001 9.69995V14.4L7.5 15.2V10.5Z" fill="#FFBC00"/>
<path d="M13.2 32.6L11.8 31.8V36.5V37.3L13.2 38.1V37.3V32.6ZM13.2 26.3L11.8 25.5V30.2L13.2 31V26.3ZM13.2 20L11.8 19.2V23.9L13.2 24.7V20ZM13.2 13.8L11.8 13V17.7L13.2 18.5V13.8Z" fill="#FFBC00"/>
<path d="M18.8 35.8999L17.4 35.1V39.8V40.6L18.8 41.3999V40.6V35.8999ZM17.9 29L17.5 28.8V33.5L17.9 33.7L18.9 33.1V30.7L17.9 29ZM18.9 23.3L17.5 22.5V27.2L18.9 28V23.3ZM18.9 17L17.5 16.2V20.8999L18.9 21.7V17Z" fill="#FFBC00"/>
<path d="M24.5 39.2L23.1 38.4V43.1V43.9L24.5 44.7V43.9V39.2ZM24.5 32.9L23.8 32.5L23.1 32.9V35.7L24.1 37.4L24.5 37.6V32.9ZM24.5 26.6L23.1 25.8V30.5L24.5 31.3V26.6ZM24.6 20.3L23.2 19.5V24.2L24.6 25V20.3Z" fill="#FFBC00"/>
<path d="M29.5 27.8L28.8 27.4L24.5 25L23.1 24.2L18.9 21.7L17.5 20.9L13.2 18.4L11.8 17.6L7.5 15.1L6.10001 14.3L1.89999 11.9L1.10001 11.5V13.1L1.79999 13.5L6.10001 15.9L7.5 16.8L11.8 19.2L13.2 20L17.5 22.5L18.9 23.3L23.1 25.8L24.5 26.6L28.8 29.1L29.5 29.5V27.8Z" fill="#FFBC00"/>
<path d="M29.5 34.1L28.8 33.7L24.5 31.2L23.1 30.3999L18.9 28L17.4 27.2L13.2 24.7L11.8 23.8999L7.5 21.3999L6.10001 20.6L1.79999 18.1L1.10001 17.7V19.4L1.79999 19.8L6.10001 22.2L7.5 23L11.8 25.5L13.2 26.3L17.4 28.8L17.9 29L17.7 28.7L18.6 28.1L19.9 30.2L21.8 31.3L23 30.6L24 32.3L23.8 32.3999L24.5 32.8999L28.8 35.3L29.5 35.7V34.1Z" fill="#FFBC00"/>
<path d="M29.5 40.4L28.8 40L24.5 37.5L24.1 37.3L24.3 37.6L23.3 38.2L22 36.1L19.8 34.8L18.6 35.5L17.6 33.8L17.8 33.7L17.4 33.4L13.2 31L11.7 30.2L7.5 27.7L6.10001 26.9L1.79999 24.4L1.10001 24V25.6L1.79999 26.1L6.10001 28.5L7.5 29.3L11.7 31.8L13.2 32.6L17.4 35.1L18.8 35.9L23.1 38.3L24.5 39.2L28.8 41.6L29.5 42V40.4Z" fill="#FFBC00"/>
<path d="M1.89999 5.5999V4.1999C1.89999 2.1999 3.3 1.3999 5 2.3999L25.7 14.3999C27.4 15.3999 28.8 17.7999 28.8 19.7999V21.1999L1.89999 5.5999ZM25.7 12.7999L5 0.799905C2.5 -0.600095 0.5 0.499903 0.5 3.3999V4.7999V6.3999L6.20001 9.6999L7.60001 10.4999L11.9 12.9999L13.3 13.7999L17.6 16.2999L19 17.0999L23.3 19.5999L24.7 20.3999L30.4 23.6999V20.6999C30.3 17.6999 28.2 14.1999 25.7 12.7999Z" fill="#FFBC00"/>
<path d="M19.9 32.3998L18.9 32.9998L17.9 33.5999L17.7 33.6998L18.7 35.3998L19.9 34.6998L21 34.0999L19.9 32.3998ZM23 30.5999L21.8 31.2999L20.9 31.7999L21.9 33.4998L23.1 32.7999L23.8 32.3998L24 32.2999L23 30.5999Z" fill="#FFBC00"/>
<path d="M18.6 28.0999L17.7 28.6998L17.9 28.9998L18.8 30.6998L19.9 32.3998L20.9 34.1998L22 36.0999L23.3 38.1998L24.3 37.5999L24.1 37.2999L23.1 35.5999L21.9 33.5999L20.9 31.8998L19.9 30.1998L18.6 28.0999Z" fill="#FFBC00"/>
`,
    '<path d="M20.1 16.9L3.10001 7.09999L3 33.1L20 42.9L20.1 16.9ZM11.6 38.8C10.3 38.1 9.3 38.6 9.3 39.9C9.3 41.3 10.3 43 11.6 43.7C12.9 44.4 13.9 43.9 13.9 42.6C13.9 41.2 12.9 39.5 11.6 38.8ZM11.6 42.1C11.1 41.8 10.7 41.2 10.7 40.8C10.7 40.3 11.1 40.2 11.6 40.5C12.1 40.8 12.5 41.4 12.5 41.8C12.4 42.2 12.1 42.4 11.6 42.1ZM4.5 32.3L4.60001 9.49999L18.7 17.7L18.6 40.5L4.5 32.3ZM4.60001 40.5C2.80001 39.5 1.39999 37.2 1.39999 35.4L1.5 4.49999C1.5 2.69999 2.9 2.09999 4.7 3.09999L18.7 11.2C20.5 12.2 21.9 14.5 21.9 16.3L21.8 47.2C21.8 49 20.4 49.6 18.6 48.6L4.60001 40.5ZM18.7 9.49999L4.7 1.39998C2.2 -0.100019 0.100006 0.899993 0.100006 3.59999L0 34.5C0 37.2 2.00001 40.6 4.60001 42L18.6 50.1C21.1 51.6 23.2 50.6 23.2 47.9L23.3 17C23.3 14.3 21.2 11 18.7 9.49999Z" fill="#FFBC00"/>',
  ],
  logo: '<path d="M367 44.2C377 69.2 364.8 97.5 339.9 107.5C324.5 113.7 307.8 111.4 295 102.9C287 97.6 280.4 89.9 276.6 80.3C266.6 55.3 278.7 27 303.7 17C313.3 13.2 323.4 12.6 332.8 14.8C347.7 18.3 360.8 28.8 367 44.2Z" fill="url(#mac8_radial)"/><path d="M322.5 28.5C303.8 28.5 288.6 43.7 288.6 62.4C288.6 81.1 303.8 96.3 322.5 96.3C341.2 96.3 356.4 81.1 356.4 62.4C356.4 43.7 341.2 28.5 322.5 28.5ZM325.2 92.4C320.8 92.8 316.6 92.2 312.7 90.9C311.7 90.6 310.7 90.1 309.8 89.5C302.2 84.1 309.8 74.3 316.1 68.1C319.2 65 319.2 59.9 316.1 56.8C309.8 50.6 302.2 40.8 309.8 35.4C310.7 34.8 311.6 34.3 312.7 34C315.8 32.9 319.1 32.3 322.5 32.3C339.9 32.3 353.8 47 352.6 64.6C351.6 79.2 339.8 91.1 325.2 92.4Z" fill="#FAFBFB"/>',
  all: `
<path d="M321.6 264.3V282.9C320 282.9 318.4 282.6 317.2 281.9L221.4 226.6C220.2 225.9 219.5 225 219.5 224V207.9C219.5 207.2 219.8 206.5 220.5 206L234.4 214L321.6 264.3Z" fill="#ECECEC"/>
<path d="M321.6 264.3V282.9C323.2 282.9 324.8 282.6 326 281.9L421.8 226.6C423 225.9 423.7 225 423.7 224V207.9C423.7 207.2 423.4 206.5 422.7 206L408.8 214L321.6 264.3Z" fill="#E1E0E0"/>
<path d="M421.9 210.5L326.1 265.8C324.9 266.5 323.3 266.9 321.6 266.9C320 266.9 318.4 266.6 317.1 265.8L221.3 210.5C220.1 209.8 219.4 208.9 219.4 207.9C219.4 207.2 219.7 206.5 220.4 206C220.6 205.8 220.9 205.6 221.3 205.4L317.1 150.2C319.6 148.8 323.6 148.8 326 150.2L421.8 205.4C424.3 206.7 424.3 209 421.9 210.5Z" fill="#FAFBFB"/>
<path d="M318.6 161.3L240.8 206.2C239.2 207.1 239.2 208.7 240.8 209.6L318.6 254.5C320.2 255.4 322.9 255.4 324.5 254.5L402.3 209.6C403.9 208.7 403.9 207.1 402.3 206.2L324.5 161.3C322.9 160.3 320.3 160.3 318.6 161.3Z" fill="#00E0C7"/>
<path d="M402.4 206.2L324.6 161.3C323.8 160.8 322.7 160.6 321.6 160.6V163.6L401.9 209.9L402.4 209.6C404 208.7 404 207.1 402.4 206.2Z" fill="#00C9AC"/>
<path d="M321.6 160.6C320.5 160.6 319.4 160.8 318.6 161.3L240.8 206.2C239.2 207.1 239.2 208.7 240.8 209.6L241.3 209.9L321.6 163.6V160.6Z" fill="#00AF96"/>
<path d="M322.2 187.3L345.2 174C345.6 173.8 345.6 173.5 345.2 173.3L322.2 160C321.8 159.8 321.3 159.8 320.9 160L297.9 173.3C297.5 173.5 297.5 173.8 297.9 174L320.9 187.3C321.3 187.5 321.9 187.5 322.2 187.3Z" stroke="white" stroke-width="1.1054" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M321.6 178.6V231.9C323 231.9 324.3 231.6 325.4 231L374.7 202.5C376.8 201.3 378.5 198.4 378.5 196V178.6H321.6Z" fill="#FF8400"/>
<path d="M264.8 178.6V196C264.8 198.4 266.5 201.3 268.6 202.5L317.9 231C318.9 231.6 320.3 231.9 321.7 231.9V178.6H264.8Z" fill="#FF9A00"/>
<path d="M317.8 210.5L266.3 180.7C264.2 179.5 264.2 177.5 266.3 176.3L317.8 146.6C319.9 145.4 323.3 145.4 325.4 146.6L376.9 176.3C379 177.5 379 179.5 376.9 180.7L325.4 210.5C323.3 211.7 319.9 211.7 317.8 210.5Z" fill="#FFBC00"/>
<path d="M276.9 177.2L319.1 152.8C320.5 152 322.7 152 324.1 152.8L366.3 177.2C367.7 178 367.7 179.3 366.3 180.1L324.1 204.5C322.7 205.3 320.5 205.3 319.1 204.5L276.9 180.1C275.5 179.3 275.5 178 276.9 177.2Z" fill="none" stroke="white" stroke-width="1.1054" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M322.9 158.9L356.1 177.9C356.8 178.3 356.8 179 356.1 179.4L322.9 198.4C322.2 198.8 321.1 198.8 320.4 198.4L287.5 179.4C286.8 179 286.8 178.3 287.5 177.9L320.4 158.9C321 158.5 322.2 158.5 322.9 158.9Z" fill="none" stroke="white" stroke-width="1.1054" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M277.8 197.3C277.8 196.2 277 194.8 276 194.2C275.5 193.9 275 193.9 274.7 194.1L267.4 198.3C265.9 199.2 264.7 201.3 264.7 203V213C264.7 213.4 264.9 213.7 265.4 214C266.4 214.6 268 214.6 269 214C269.5 213.7 269.7 213.3 269.7 213V203.1C269.7 202.9 269.8 202.7 270 202.6L277.2 198.5C277.6 198.3 277.8 197.9 277.8 197.3Z" fill="url(#mac0_linear)"/>
<path d="M288.8 203.7C288.8 202.6 288 201.2 287 200.6C286.5 200.3 286 200.3 285.7 200.5L278.4 204.7C276.9 205.6 275.7 207.7 275.7 209.4V219.4C275.7 219.8 275.9 220.1 276.4 220.4C277.4 221 279 221 280 220.4C280.5 220.1 280.7 219.7 280.7 219.4V209.5C280.7 209.3 280.8 209.1 281 209L288.2 204.9C288.6 204.6 288.8 204.2 288.8 203.7Z" fill="url(#mac1_linear)"/>
<path d="M299.7 210C299.7 208.9 298.9 207.5 297.9 206.9C297.4 206.6 296.9 206.6 296.6 206.8L289.3 211C287.8 211.9 286.6 214 286.6 215.7V225.7C286.6 226.1 286.8 226.4 287.3 226.7C288.3 227.3 289.9 227.3 290.9 226.7C291.4 226.4 291.6 226 291.6 225.7V215.8C291.6 215.6 291.7 215.4 291.9 215.3L299.1 211.2C299.5 211 299.7 210.6 299.7 210Z" fill="url(#mac2_linear)"/>
<path d="M310.6 216.4C310.6 215.3 309.8 213.9 308.8 213.3C308.3 213 307.8 213 307.5 213.2L300.2 217.4C298.7 218.3 297.5 220.4 297.5 222.1V232.1C297.5 232.5 297.7 232.8 298.2 233.1C299.2 233.7 300.8 233.7 301.8 233.1C302.3 232.8 302.5 232.4 302.5 232.1V222.2C302.5 222 302.6 221.8 302.8 221.7L310 217.6C310.4 217.3 310.6 216.9 310.6 216.4Z" fill="url(#mac3_linear)"/>
<path d="M365.4 197.3C365.4 196.2 366.2 194.8 367.2 194.2C367.7 193.9 368.2 193.9 368.5 194.1L375.8 198.3C377.3 199.2 378.5 201.3 378.5 203V213C378.5 213.4 378.3 213.7 377.8 214C376.8 214.6 375.2 214.6 374.2 214C373.7 213.7 373.5 213.3 373.5 213V203.1C373.5 202.9 373.4 202.7 373.2 202.6L366 198.5C365.6 198.3 365.4 197.9 365.4 197.3Z" fill="url(#mac4_linear)"/>
<path d="M354.5 203.7C354.5 202.6 355.3 201.2 356.3 200.6C356.8 200.3 357.3 200.3 357.6 200.5L364.9 204.7C366.4 205.6 367.6 207.7 367.6 209.4V219.4C367.6 219.8 367.4 220.1 366.9 220.4C365.9 221 364.3 221 363.3 220.4C362.8 220.1 362.6 219.7 362.6 219.4V209.5C362.6 209.3 362.5 209.1 362.3 209L355.1 204.9C354.7 204.6 354.5 204.2 354.5 203.7Z" fill="url(#mac5_linear)"/>
<path d="M343.5 210C343.5 208.9 344.3 207.5 345.3 206.9C345.8 206.6 346.3 206.6 346.6 206.8L353.9 211C355.4 211.9 356.6 214 356.6 215.7V225.7C356.6 226.1 356.4 226.4 355.9 226.7C354.9 227.3 353.3 227.3 352.3 226.7C351.8 226.4 351.6 226 351.6 225.7V215.8C351.6 215.6 351.5 215.4 351.3 215.3L344.1 211.2C343.7 211 343.5 210.6 343.5 210Z" fill="url(#mac6_linear)"/>
<path d="M332.6 216.4C332.6 215.3 333.4 213.9 334.4 213.3C334.9 213 335.4 213 335.7 213.2L343 217.4C344.5 218.3 345.7 220.4 345.7 222.1V232.1C345.7 232.5 345.5 232.8 345 233.1C344 233.7 342.4 233.7 341.4 233.1C340.9 232.8 340.7 232.4 340.7 232.1V222.2C340.7 222 340.6 221.8 340.4 221.7L333.2 217.6C332.8 217.3 332.6 216.9 332.6 216.4Z" fill="url(#mac7_linear)"/>
<path opacity="0.3" d="M327 6.59995C325.4 7.59995 323.6 7.99995 321.6 7.89995H321.5H321.6V255.2C322.8 255.2 324 254.9 325.2 254.1L401.5 210.1C402.9 209.3 403.6 208.6 403.6 207.7V6.19995L327 6.59995Z" fill="url(#mac9_linear)"/>
<path opacity="0.5" d="M321.5 7.89995C321 7.89995 320.6 7.89995 320.1 7.79995H320C319.6 7.79995 319.1 7.69995 318.7 7.59995C318.4 7.49995 318.2 7.39995 317.9 7.39995C317.7 7.29995 317.6 7.29995 317.4 7.19995C317 6.99995 316.6 6.79995 316.2 6.59995L239.6 6.19995V207.7C239.6 208.6 240.2 209.2 241.1 209.8L318 254.2C319.2 255 320.3 255.3 321.5 255.3H321.6L321.5 7.89995C321.6 7.89995 321.5 7.89995 321.5 7.89995Z" fill="url(#mac10_linear)"/>
<defs>
<linearGradient id="mac0_linear" x1="264.77" y1="204.252" x2="277.825" y2="204.252" gradientUnits="userSpaceOnUse">
<stop stop-color="#FAFBFB"/>
<stop offset="0.3827" stop-color="#F5F5F5"/>
<stop offset="0.8791" stop-color="#E6E5E5"/>
<stop offset="1" stop-color="#E1E0E0"/>
</linearGradient>
<linearGradient id="mac1_linear" x1="275.709" y1="210.596" x2="288.764" y2="210.596" gradientUnits="userSpaceOnUse">
<stop stop-color="#FAFBFB"/>
<stop offset="0.3827" stop-color="#F5F5F5"/>
<stop offset="0.8791" stop-color="#E6E5E5"/>
<stop offset="1" stop-color="#E1E0E0"/>
</linearGradient>
<linearGradient id="mac2_linear" x1="286.648" y1="216.94" x2="299.702" y2="216.94" gradientUnits="userSpaceOnUse">
<stop stop-color="#FAFBFB"/>
<stop offset="0.3827" stop-color="#F5F5F5"/>
<stop offset="0.8791" stop-color="#E6E5E5"/>
<stop offset="1" stop-color="#E1E0E0"/>
</linearGradient>
<linearGradient id="mac3_linear" x1="297.586" y1="223.284" x2="310.641" y2="223.284" gradientUnits="userSpaceOnUse">
<stop stop-color="#FAFBFB"/>
<stop offset="0.3827" stop-color="#F5F5F5"/>
<stop offset="0.8791" stop-color="#E6E5E5"/>
<stop offset="1" stop-color="#E1E0E0"/>
</linearGradient>
<linearGradient id="mac4_linear" x1="378.463" y1="204.252" x2="365.408" y2="204.252" gradientUnits="userSpaceOnUse">
<stop stop-color="#FAFBFB"/>
<stop offset="0.3827" stop-color="#F5F5F5"/>
<stop offset="0.8791" stop-color="#E6E5E5"/>
<stop offset="1" stop-color="#E1E0E0"/>
</linearGradient>
<linearGradient id="mac5_linear" x1="367.524" y1="210.596" x2="354.47" y2="210.596" gradientUnits="userSpaceOnUse">
<stop stop-color="#FAFBFB"/>
<stop offset="0.3827" stop-color="#F5F5F5"/>
<stop offset="0.8791" stop-color="#E6E5E5"/>
<stop offset="1" stop-color="#E1E0E0"/>
</linearGradient>
<linearGradient id="mac6_linear" x1="356.585" y1="216.94" x2="343.531" y2="216.94" gradientUnits="userSpaceOnUse">
<stop stop-color="#FAFBFB"/>
<stop offset="0.3827" stop-color="#F5F5F5"/>
<stop offset="0.8791" stop-color="#E6E5E5"/>
<stop offset="1" stop-color="#E1E0E0"/>
</linearGradient>
<linearGradient id="mac7_linear" x1="345.648" y1="223.284" x2="332.592" y2="223.284" gradientUnits="userSpaceOnUse">
<stop stop-color="#FAFBFB"/>
<stop offset="0.3827" stop-color="#F5F5F5"/>
<stop offset="0.8791" stop-color="#E6E5E5"/>
<stop offset="1" stop-color="#E1E0E0"/>
</linearGradient>
<radialGradient id="mac8_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(304.563 33.6768) scale(60.4606)">
<stop stop-color="#00E0C7"/>
<stop offset="1" stop-color="#00AF96"/>
</radialGradient>
<linearGradient id="mac9_linear" x1="406.458" y1="207.992" x2="321.469" y2="58.2902" gradientUnits="userSpaceOnUse">
<stop stop-color="#00E0C7"/>
<stop offset="1" stop-color="#00E0C7" stop-opacity="0"/>
</linearGradient>
<linearGradient id="mac10_linear" x1="236.85" y1="208.517" x2="305.023" y2="87.2724" gradientUnits="userSpaceOnUse">
<stop stop-color="#00E0C7"/>
<stop offset="1" stop-color="#00E0C7" stop-opacity="0"/>
</linearGradient>
</defs>
`
}